import revive_payload_client_ZyfdwIW3I2 from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HIMvHoCcbb from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kQEelLySN5 from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_fmjpsG5OaS from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_98yRDik5N2 from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BZvhV6lWul from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Z0cQRPeVZC from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/yangfan/workspace/github/pnpm/use-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Gx0fkcb3HD from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_aqjile33ir5dbnmo5sqt75g7rm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JLIjOvdxu4 from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.4_rollup@4.21.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_maO3nvoKug from "/Users/yangfan/workspace/github/pnpm/use-nuxt/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/Users/yangfan/workspace/github/pnpm/use-nuxt/.nuxt/unocss.mjs";
import Vue3Marquee_client_kXG7sU1suh from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/plugins/Vue3Marquee.client.ts";
export default [
  revive_payload_client_ZyfdwIW3I2,
  unhead_HIMvHoCcbb,
  router_kQEelLySN5,
  navigation_repaint_client_fmjpsG5OaS,
  check_outdated_build_client_98yRDik5N2,
  chunk_reload_client_BZvhV6lWul,
  plugin_vue3_Z0cQRPeVZC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Gx0fkcb3HD,
  plugin_JLIjOvdxu4,
  plugin_client_maO3nvoKug,
  unocss_MzCDxu9LMj,
  Vue3Marquee_client_kXG7sU1suh
]