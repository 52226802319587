import { default as _91_46_46_46all_93oPPT8URHILMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/[...all].vue?macro=true";
import { default as indexClNlR5qZWUMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/index/index.vue?macro=true";
import { default as indexibTzypiliHMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/s-screen/index.vue?macro=true";
import { default as indexaG9JTsq82zMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/s-screen/task/index.vue?macro=true";
import { default as s_45screenhv1i5PJBnCMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/s-screen.vue?macro=true";
import { default as indexWJFEi4A7zuMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/v-screen/data/index.vue?macro=true";
import { default as indexehDEbJsSkZMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/v-screen/index/index.vue?macro=true";
import { default as v_45screenYvcx4BbqvFMeta } from "/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/v-screen.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexClNlR5qZWUMeta || {},
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: s_45screenhv1i5PJBnCMeta?.name,
    path: "/s-screen",
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/s-screen.vue").then(m => m.default || m),
    children: [
  {
    name: "s-screen",
    path: "",
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/s-screen/index.vue").then(m => m.default || m)
  },
  {
    name: "s-screen-task",
    path: "task",
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/s-screen/task/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: v_45screenYvcx4BbqvFMeta?.name,
    path: "/v-screen",
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/v-screen.vue").then(m => m.default || m),
    children: [
  {
    name: "v-screen-data",
    path: "data",
    meta: indexWJFEi4A7zuMeta || {},
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/v-screen/data/index.vue").then(m => m.default || m)
  },
  {
    name: "v-screen-index",
    path: "",
    meta: indexehDEbJsSkZMeta || {},
    component: () => import("/Users/yangfan/workspace/github/pnpm/use-nuxt/app/pages/v-screen/index/index.vue").then(m => m.default || m)
  }
]
  }
]